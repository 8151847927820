<template>
  <div class="wrapper" :style="{backgroundColor:`${color}`}">
    <div class="right-content">
      <h3 class="title">推荐阅读</h3>
      <div>
        
        <ul class="hot-list">
          <li v-for="(item,index) in list" :key="index" @click="goDetail(item)">
            <!-- <img v-if="index === 0" :src="titleImg || '/system/file/merck/1351465838111395841'
" class="img" alt=""> -->
            <p class="item-title">{{item.title}}</p>
          </li>
        </ul>
      </div>
    </div>
    <button class="change" @click="handleChange" :style="{backgroundColor:`${color}`}">
      <span class="icon"></span>换一换
    </button>
  </div>
</template>

<script>
import indexApi from '@/api/index'
export default {
  name: "recommended-reading",
  data() {
    return {
      recommendData: {
        titleImg: '',
        list: []
      },
      titleImg: '',
      list: [],
      page: 1
    }
  },
  props:{
    newsId:{
        default: '',
    },
    ignoreId: {
      default: '',
      type: [Number, String]
    },
    color: {
        default: '#503291',
        type: String,
    },
  },
  created() {
    this.getRecommendList();
  },
  mounted() {
    // this.getDataRecommendData();
  },
  watch:{
     $route: {
      handler: function(){
        window.location.reload()
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    
    handleChange() {
		let that = this;
        that.page = that.page + 1;
        that.getRecommendList();
		let button_params = {
			button: "换一换",
			buttonContent: "",
			dataId: parseInt(that.$route.params.id),
			type: parseInt(3)
		}
		that.$point.buttonClickFn(button_params);
    },

    // 获取推荐阅读 getRecommendList
    getRecommendList() {
      
      if(this.newsId){ //资讯推荐阅读
        console.log('资讯推荐阅读',this.newsId)
        let params = {
            "condition": {
                "ignoreId":this.ignoreId,//当前动态ID
                "newsId":this.newsId,
            },
            "page": this.page,
            "size": 5
        };
        indexApi.newsRecommendLis(params).then((res)=>{
            // this.list = [];
            if(res && res.code === 1000){
            let data = res && res.data && res.data.list;
            if(data.length > 0) {
                this.list = data;
                this.titleImg = this.list && this.list[0] && this.list[0].titleImg;
                
                if( this.page == res.data.pageNums) {
                    this.page = 0;
                }
            }
            
            }
        },(err)=>{
            this.$message.success(err && err.message || '获取失败，请联系管理员！')
        })
      }else{
          let params = {
            "condition": {
            },
            "page": this.page,
            "size": 5
          };
         indexApi.getRecommendList(params).then((res)=>{
            // this.list = [];
            if(res && res.code === 1000){
            let data = res && res.data && res.data.list;
            if(data.length > 0) {
                this.list = data;
                this.titleImg = this.list && this.list[0] && this.list[0].titleImg;
                
                if( this.page == res.data.pageNums) {
                    this.page = 0;
                }
            }
            
            }
        },(err)=>{
            this.$message.success(err && err.message || '获取失败，请联系管理员！')
        }) 
      }
    },

    // 换一换分页
    changePage() {
      
    },

    // 跳转详情
    goDetail(item) {
      let id = item && item.id;
      let productId = item.productIds;
      if(item.type == 5){ //产品动态
        this.$router.push({
            name: 'productDetail',
            params:{ id: id},
            query: {
                productIds:productId
                // producType: realmId
            }
        })
      }else{ // 新闻动态
        let url = '/news-details/' + id
        window.location.href = url;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  background-color: #503291;
  border-radius: .2rem;
  padding-bottom: .25rem;
  position: relative;
  .right-content {
    padding: .4rem .4rem .3rem;
    border-radius: .2rem;
    background: #fff;
    height: 5.1rem;
    overflow: hidden;
    .title {
      color: #503291;
      padding-left: .27rem;
      background: url("~./images/hot.png") left top / .16rem .2rem no-repeat;
      font-size: .2rem;
      padding-bottom: .17rem;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: .2rem;
    }
    .img {
      display: block;
      width: 100%;
      height: 2.07rem;
      border-radius: .08rem .4rem .08rem .08rem;
      margin-bottom: .11rem;
    }
    .hot-list {
      li {
        line-height: .24rem;
        color: #1a1a1a;
        font-size: .16rem;
        padding-bottom: .16rem;
        border-bottom: 1px dashed #ebebeb;
        margin-bottom: .16rem;
        cursor: pointer;
        .item-title{
          padding-left: .26rem;
          background: url("~./images/third.png") top .04rem left 0 / .13rem .16rem no-repeat;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          // padding: 0 .2rem;
          height: .48rem;
        }
        &:nth-of-type(1) {
            .item-title{
                background-image: url("~./images/first.png");
            }
        }
        
        &:nth-of-type(2) {
            .item-title{
                background-image: url("~./images/second.png");
            }
        }
        
      }
    }
  }
  .change {
    background-color: #503291;
    border-radius: .16rem;
    border: none;
    color: #fff;
    position: absolute;
    bottom: .09rem;
    left: 50%;
    margin-left: -0.54rem;
    width: 1.08rem;
    height: .32rem;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      .icon {
        transform: rotate(180deg);
      }
    }
    .icon {
      display: block;
      width: .18rem;
      height: .15rem;
      background: url("~./images/refresh.png") center center /100% 100%;
      margin-right: .07rem;
      transition: all .3s;
    }
  }
}
</style>
